<template>
  <app-form
    :value="value"
    :showModal="showModal"
    type="edge"
    @show="showAddEndpointModal"
    @submit="createApp"
  />
</template>

<script>
import AppForm from '@/components/apps/AppForm.vue'
export default {
  name: 'CreateEdge',
  components: {
    'app-form': AppForm,
  },
  data() {
    return {
      view: 'addAppDetails',
      showModal: this.$route.meta.showModal,
      value: {
        name: '',
        description: '',
        forwarders: [],
      },
    }
  },
  methods: {
    showAddEndpointModal() {
      this.$router.push({ name: 'addEdgeEndpoint' })
    },
    createApp() {
      this.isSubmitting = true
      this.$store
        .dispatch('edge/createApp', this.value)
        .then(() => {
          this.$router.push({ name: 'edges' })
          this.clearState()
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
  },
  watch: {
    '$route.meta'({ showModal }) {
      this.showModal = showModal
    },
  },
}
</script>
<style>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}
</style>
